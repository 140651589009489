require('./site/bootstrap')

import 'bootstrap5/js/dist/dropdown';
import 'bootstrap5/js/dist/collapse';


/*/
*  Subject
/*/
	const siteSubject = document.querySelectorAll('[np-contact]')

	if (siteSubject) {
		siteSubject.forEach(e => {
			e.addEventListener('click', event => {
				event.preventDefault()

				window.localStorage.setItem('productSelected', e.dataset.title)

				setTimeout(() => {
					window.location = e.href
				}, 100);
			})
		})
	}

	window.addEventListener('load', _ => {
		const page = document.querySelector('#app').classList.contains('contact')
		const product = window.localStorage.getItem('productSelected')
		const input = document.querySelector('[name="subject"]')

		if (page && product) {
			input.value = 'Orçamento – ' + product
			window.localStorage.removeItem('productSelected')
		}
	})
	

/*/
 *  Alert
/*/
	window.npAlert = function (params) {
		params = {
			text: params.text ? params.text : '',
			class: params.class ? params.class : 'alert alert--info',
		}

		const alert = document.getElementById('alert')

		alert.classList = params.class
		alert.innerHTML = params.text

		setTimeout(function () { alert.classList.add('is--visible') }, 300)

		alert.addEventListener('click', function () {
			this.classList.remove('is--visible')
		})
	}

	window.npAlertDismiss = function () {
		const alert = document.getElementById('alert')
		alert.classList.remove('is--visible')
	}


/*/
 *  Forms
/*/
	window.addEventListener('load', function () {
		'use strict'
		let forms = document.querySelectorAll('.needs-validation')
		Array.from(forms).forEach(function (form) {
			form.addEventListener('submit', function (event) {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()

					let invalid = form.querySelector('.form-control:invalid')

					invalid.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'center'
					})

					setTimeout(function () {
						invalid.focus()
					}, 500)
				} else {
					event.preventDefault()
					event.stopPropagation()

					npAlert({
						text: 'Enviando...',
						class: 'alert alert--info is--visible',
					})

					let data = new FormData(form)
					let fieldset = form.querySelector('fieldset')
					let button = form.querySelector('button[type=submit]')

					window.axios.post(form.action, data, {
						headers: { 'Content-Type': 'multipart/form-data' }
					}).then(res => {
						form.reset()
						fieldset.disabled = true
						button.disabled = true

						setTimeout(function () {
							npAlert({
								text: 'Dados enviados com sucesso!',
								class: 'alert alert--success is--visible',
							})
						}, 1500)

						// $('[name="' + button.dataset.form + '"] input, [name="' + button.dataset.form + '"] textarea').trigger('keydown')

						setTimeout(function () {
							npAlertDismiss()

							fieldset.disabled = false
							button.disabled = false
						}, 6000)

						form.classList.remove('was-validated')
					}).catch(error => {
						// console.log(error)

						setTimeout(function () {
							npAlert({
								text: 'Erro ao tentar enviar o formulário!',
								class: 'alert alert--error is--visible',
							})
						}, 1500)

						setTimeout(function () {
							npAlertDismiss()

							fieldset.disabled = false
							button.disabled = false
						}, 6000)
					})
				}

				form.classList.add('was-validated')
			}, false)
		})
	})